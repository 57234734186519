<template>
    <div class="about-box container">
        <h2 class="title">关于本站</h2>
        <h3 class="t">关于我</h3>
        <p>Maple是一个并非科班生的前端开发博主，目前正在努力学习前端技术，大学学的计算机信息管理学得不精，然后自己出来工作就是不断的学习，没有老师没有人带，就自己百度不断实践~</p>
        <h3 class="t">其他介绍</h3>
        <p>由于本人喜欢研究各种新的东西，想与大家互相学习有什么不对或者不同的见解请多指教，乐意倾听你们对技术的认知探讨学习，谢谢~</p>
        <h3 class="t">本站介绍</h3>
        <p>本站是对前端的一些笔记，和前端一些知识的汇总~欢迎大家来一起探讨~</p>
    </div>
</template>

<script>
export default {
    name: 'About',
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>